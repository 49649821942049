<template>
  <a-entity position="0.56 2.05 -0.43" rotation="90 0 0">
    <a-image
      class="piano-tut"
      position="1.5 0.05 -1.2"
      rotation="-90 0 0"
      src="#clockTut"
      scale="1.6 1.6 1.6"
      :animation="`startEvents: close; property: scale; to: 0 0 0; loop: true; dur: 400; loop: 0`"
      ><a-image
        class="note"
        position="-0.5 0.4 0.1"
        src="#close"
        scale="0.2 0.2 0.2"
        @click="closeMessage"
      ></a-image
    ></a-image>

    <a-entity class="clock" rotation="90 0 180" scale="0.4 0.4 0.4">
      <a-entity
        v-for="(analog, i) in placements"
        :key="i"
        :animation="`property: rotation; from: 0 0 ${analog.calc}; to: 0 0 ${
          analog.calc + 360
        }; dur: ${analog.miliSecs}; easing: linear; loop: true`"
      >
        <!-- <a-plane
          side="double"
          :position="`0 0.45 ${analog.z}`"
          :scale="analog.scale"
          :color="analog.color"
        ></a-plane> -->
        <a-gltf-model
          rotation="90 90 0"
          :src="analog.model"
          scale="2.6 2.6 2.6"
        ></a-gltf-model>

        <!-- <a-sphere radius="0.15" color="#0291D3"></a-sphere> -->
      </a-entity>
    </a-entity>
  </a-entity>
</template>

<script>
import { log } from "tone/build/esm/core/util/Debug";
export default {
  name: "Piano",
  mounted() {
    var date = new Date();
    this.seconds = date.getSeconds();
    this.minutes = date.getMinutes();
    this.hour = date.getHours();
  },
  data() {
    return {
      tutOpen: false,

      seconds: null,
      minutes: null,
      hour: null,
    };
  },
  computed: {
    placements: function () {
      let placements = [
        {
          model: "#secondsAnalog",
          z: -0.02,
          color: "red",
          scale: "0.1 1.15 0.1",
          miliSecs: 60000,
          calc: 6 * this.seconds,
        },
        {
          model: "#minutesAnalog",
          z: -0.01,
          color: "black",
          scale: "0.1 1 0.1",
          miliSecs: 3600000,
          calc: 6 * this.minutes,
        },
        {
          model: "#hourAnalog",
          z: 0,
          color: "black",
          scale: "0.1 0.65 0.1",
          miliSecs: 86400000,
          calc: 30 * this.hour,
        },
      ];
      return placements;
    },
  },
  methods: {
    closeMessage(e) {
      e.target.parentElement.emit("close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dev-page {
  position: absolute;
  background: red;
  z-index: 7;
}
.portfolio {
  display: flex;
  width: 100%;
  height: 100%;
}
.edit {
  position: absolute;
  bottom: 16px;
  left: 16px;
  // background: white;
  z-index: 5;
  transition: 0.4s;

  // max-width: 220px;
  // max-height: 300px;
  text-align: left;
  border-radius: 5px;

  padding: 20px;
  display: flex;
  overflow-x: auto;
  width: 100%;
  .item {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  label {
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 1.5rem;
    font-weight: 500;
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
}
.debug {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  z-index: 7;
}
</style>