<template>
  <a-entity
    class="piano"
    position="0.2 -1.05 0"
    scale="15 15 15"
    rotation="0 90 5"
  >
    <a-image
      v-if="!isMobile && tutOpen"
      class="piano-tut"
      position="-0.1 0.05 0.2"
      rotation="0 -90 0"
      src="#pianoTut"
      scale="0.1 0.1 0.1"
      :animation="`startEvents: close; property: scale; to: 0 0 0; loop: true; dur: 400; loop: 0`"
      ><a-image
        class="note"
        position="0.45 0.5 0.1"
        src="#close"
        scale="0.2 0.2 0.2"
        @click="closeMessage"
      ></a-image
    ></a-image>

    <a-entity
      v-for="(note, i) in notes"
      :key="`piano-note-${i}`"
      :position="`0 0 ${i * 0.021}`"
      animation="startEvents: movekey; resumeEvents: resumeEvents; property: rotation; to: 0 0 5; dur: 200; easing: linear;"
      animation__back="startEvents: back; property: rotation; to: 0 0 0; dur: 200; easing: linear;"
      @animationcomplete="keyAnimComplete($event, note)"
      @animationcomplete__back="keyBackAnimComplete($event, note)"
    >
      <a-gltf-model
        class="note"
        scale="0.7 0.5 0.5"
        src="objects/piano_piece.glb"
        position="-0.1 0 0"
        rotation="0 180 0"
        @click="playNote($event, note), (playing = true)"
      ></a-gltf-model>
    </a-entity>
    <a-entity
      v-for="(note, j) in notesBlack"
      :key="j * 25620"
      :position="`0 0 ${j * 0.021}`"
      animation="startEvents: movekey; property: rotation; to: 0 0 5; dur: 200; dir: alternate; easing: linear; loop: 2;"
      animation__back="startEvents: back; property: rotation; to: 0 0 0; dur: 200; easing: linear;"
      @animationcomplete="keyAnimComplete($event, note)"
      @animationcomplete__back="keyBackAnimComplete($event, note)"
    >
      <a-box
        class="note"
        color="black"
        width="0.0658"
        depth="0.01"
        height="0.009"
        position="-0.09 0.02 0.01"
        @click="playNote($event, note)"
        v-if="note"
      ></a-box>
    </a-entity>
  </a-entity>
</template>

<script>
import * as Tone from "tone";

export default {
  name: "Piano",
  mounted() {
    // this.synth = new Tone.Synth().toDestination();

    this.sampler = new Tone.Sampler({
      urls: {
        C4: "C4.mp3",
        "D#4": "Ds4.mp3",
        "F#4": "Fs4.mp3",
        A4: "A4.mp3",
      },
      release: 1,
      baseUrl: "https://tonejs.github.io/audio/salamander/",
    }).toDestination();
  },
  props: ["isMobile"],
  data() {
    return {
      tutOpen: true,
      playing: false,
      synth: null,
      sampler: null,
      notes: ["C4", "D4", "E4", "F4", "G4", "A4", "B4"],
      notesBlack: ["C#4", "D#4", "", "F#4", "G#4", "A#4"],
    };
  },
  methods: {
    playNote: function (e, note) {
      e.target.emit("movekey");
      this.sampler.triggerAttackRelease(note, 4);
    },
    keyAnimComplete: function (e, note) {
      e.target.emit("back");
    },
    keyBackAnimComplete: function (e, note) {
      e.target.emit("back");
      this.playing = false;
    },
    closeMessage: function (e, note) {
      e.target.parentElement.emit("close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>